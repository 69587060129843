import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

const HeaderSubtitle = ({ subtitle, direction = 'left' }) => {
  const [ open, setOpen ] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const directionClassName = (direction) => {
    var classname = direction === 'left' ? 'user-profile-left' : 'user-profile-right'
    return classNames('user-profile', 'd-flex', 'flex-row', 'align-items-center', classname);
  }
  
  return (
    <React.Fragment>
      {/* {subtitle && subtitle.length > 0 ? (
        <div className={classNames('user-profile', 'd-flex', 'flex-row', 'align-items-center', directionClassName(direction) )}>
          <div className="header-menu d-inline-block ml-2 mr-3">
            <a onClick={handleClick} className="subtitle">{subtitle}</a>
          </div>
          <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
          >
            <MenuItem onClick={handleRequestClose}>
              {subtitle}
            </MenuItem>
            <MenuItem onClick={handleRequestClose}>
              {subtitle}
            </MenuItem>
            <MenuItem onClick={handleRequestClose}>
              {subtitle}
            </MenuItem>
          </Menu>
        </div>
      ) : (<React.Fragment />)} */}
    </React.Fragment>
  );
}

export default HeaderSubtitle;


// <div>
// { subtitle && subtitle.length > 0 ? (
//     <div className="header-menu d-inline-block ml-2 mr-3">
//       <div className="subtitle">{subtitle}test</div>
//     </div>  
//   ) : (
//     <div />
//   )
// }
// </div>


// <div className="user-detail">
// <h4 className="user-name" onClick={handleClick}>{firstName} {lastName}
//   <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
// </h4>
// </div>

