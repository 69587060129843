// import React from 'react';

export default function MainAppReducer(state, action) {
  switch (action.type) {
    case "TEST":
      return {
        ...state,
        text: action.text
      }
    default:
      return state;
  }
}
