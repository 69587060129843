import * as React from "react";
import { IconOther } from "@bit/hlouzek.rhplus.icons";
import { connect } from "react-redux";
import Enumerable from "linq";
import useFileAttachement from "components/FileAttachement";
import CircularProgress from "@material-ui/core/CircularProgress";
import useApi from '@bit/hlouzek.rhplus.api-component';
import { quotes } from 'api/apiService';

const HeaderExport = (props) => {
  const { downloadFileStream } = useFileAttachement();
  const [loading, setLoading] = React.useState(false);
  const { 
    exportQuoteToExcelSimple,
    exportQuoteToExcelComplex
  } = quotes;
  const { callApi } = useApi(process.env.REACT_APP_PROXY);
  
  const onFileClick = e => {
    e.preventDefault();
    setLoading(true);

    // callApi({...exportQuoteToExcelSimple(props.quoteId)})
    callApi({...exportQuoteToExcelComplex(props.quoteId)})
    .then(response => {
      downloadFileStream(response.data, props.quoteName, response.headers["content-type"]).then(() => {
        setLoading(false);
      })  
    });

  };

  const ShowIcon = () => {
    if (!props.quoteId)
      return null;

    if (!!loading) {
      return (
        <div>
          <CircularProgress color="white" style={{width: 20, height: 20}} />
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <a href="" onClick={onFileClick}>
            <IconOther className="rhplus-2x" name="excel" />
          </a>
          <div>quote</div>
        </div>
      );
    }
  };

  return (
    <div>
      <ShowIcon />
    </div>
  );
};

const mapStateToProps = ({ quotes }) => {
  const { quote: {id, shortName, revision} = {}} = quotes;
  return {
    quoteId: id,
    quoteName: `${shortName} rev ${revision}.xlsx`
  };
};

export default connect(mapStateToProps)(HeaderExport);
