import { 
  PROJECTS_FETCH_DATA,
  PROJECTS_UPDATE_ITEM,
  PROJECTS_REMOVE_ITEM,
  PROJECTS_DEACTIVE_ITEM
} from 'constants/ActionTypes';

const initialQuotes = {}

export default (state = initialQuotes, action) => {

  switch (action.type) {
    case PROJECTS_FETCH_DATA:
      return {
        ...state,
        projects: action.projects
      }

    case PROJECTS_UPDATE_ITEM:
      
      return {
        ...state,
        projects: state.projects.map(project => {
          if (project.id === action.project.id) {
            return action.project;
          } else {
            return project
          }
        })
      }

    case PROJECTS_DEACTIVE_ITEM:
      return {
        ...state,
        projects: state.projects.map(project => {
          if (project.id === action.project.id) {
            return {
              ...project,
              active: !project.active
            }
          } else {
            return project;
          }
        })
      }

    case PROJECTS_REMOVE_ITEM:
      return {
        ...state,
        projects: state.projects.filter(f => f.id !== action.project.id)
      }

    default:
      return state;
  } 
};


