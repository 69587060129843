import jsIndexedDB from 'js-indexedb/dist/js-IndexedDB';
import {configuration} from './configuration';

const tableEnum = {
  country: 'country',
  location: 'location',
  warehouse: 'warehouse',
  worksOrderDepartment: 'worksOrderDepartment',
  projectManager: 'projectManager',
  vehicle: 'vehicle',
  quoteDeliveryScheduleTemplate: 'quoteDeliveryScheduleTemplate',
  quoteDeliveryScheduleDispatcher: 'quoteDeliveryScheduleDispatcher',
  client: 'client',
  quoteType: 'quoteType',
  storeType: 'storeType'
}

class IndexedDb {

  get table() {
    return tableEnum;
  }

  build = async () => {
    if (!this._db) {
      this._db = await jsIndexedDB(configuration);
    }
  }

  getDb = async () => {
    await this.build();
    return this._db;
  }

  getData = async (table) => {
    await this.build();
    return await this._db.getAll(table);
  }

  clients = async () => await this.getData(tableEnum.client);
  countries = async () => await this.getData(tableEnum.country);
  locations = async () => await this.getData(tableEnum.location);
  warehouses = async () => await this.getData(tableEnum.warehouse);
  worksOrderDepartments = async () => await this.getData(tableEnum.worksOrderDepartment);
  projectManagers = async () => await this.getData(tableEnum.projectManager);
  vehicles = async () => await this.getData(tableEnum.vehicle);
  quoteDeliveryScheduleTemplates = async () => await this.getData(tableEnum.quoteDeliveryScheduleTemplate);
  quoteDeliveryScheduleDispatcher = async () => await this.getData(tableEnum.quoteDeliveryScheduleDispatcher);
  quoteTypes = async () => await this.getData(tableEnum.quoteType);
  storeTypes = async () => await this.getData(tableEnum.storeType);

  addData = async (tableName, data) => {
    await this.build();
    await this._db.addMany(tableName, data);
  }
}

export default IndexedDb;