
export const getColumnDefs = (columns) => {
  if (columns === null) return null;
  
  var result = columns.map(item =>
     {
        item.width = item.widthInPixels;
        // merging
        item.colSpan = function colSpan(params) {
          if (params.data === undefined) return null;
          if (params.data.style[params.colDef.field] === undefined) return null;

          var colSpan = params.data.style[params.colDef.field].colSpan;
          if (colSpan === null)
            return 1;
          else 
            return colSpan;  
        }

        // // styling
        item.cellStyle = function cellStyle(params) {
          if (params.data === undefined) return null;
          if (params.data.style[params.colDef.field] === undefined) return null;

          var style = params.data.style[params.colDef.field];
          var retVal = style;
          if (style.backgroundColor != null)
            retVal = {
              ...retVal,
              backgroundColor: style.backgroundColor
            };

          if (style.color != null)
            retVal = {
              ...retVal,
              color: style.color
            }

          return retVal;
        }

      return item;
  });
  
 return result;
};