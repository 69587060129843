import React, { useContext, useReducer } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import MainAppContext from './MainAppContext';
import MainAppReducer from './MainAppReducer';
import configureStore, { history } from './store';
import App from './containers/App';
import useApi from '@bit/hlouzek.rhplus.api-component';
import {quotes} from 'api/apiService';
import { LicenseManager } from "ag-grid-enterprise";
// LicenseManager.setLicenseKey("MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10");
import IndexedDb from 'IndexedDb';
import Enumerable from 'linq';
import { LoaderContainer } from 'components/Loader';

export const store = configureStore();
export default function MainApp() {
  const MainAppInitial = useContext(MainAppContext);
  const [state, dispatch] = useReducer(MainAppReducer, MainAppInitial);
  const {callApi} = useApi(process.env.REACT_APP_PROXY);
  const {allCatalogs} = quotes;
  const indexedDb = new IndexedDb();
  const pusherConfig = require(`${process.env.REACT_APP_PUSHER}`);

  React.useEffect(() => {
    fillIndexedDb();
  }, []);

  const fillIndexedDb = async () => {    
    const response = await callApi({...allCatalogs()});
    const clients = Enumerable.from(response.data.client).orderBy(o => o.name).toArray();
    const countries = Enumerable.from(response.data.country).orderBy(o => o.name).toArray();
    const locations = Enumerable.from(response.data.location).orderBy(o => o.code).toArray();
    const warehouses = Enumerable.from(response.data.warehouse).orderBy(o => o.code).toArray();
    const worksOrderDepartments = Enumerable.from(response.data.worksOrderDepartment).orderBy(o => o.name).toArray();
    const quoteProjectManagers = Enumerable.from(response.data.quoteProjectManager).orderBy(o => o.name) .toArray();
    const vehicles = response.data.vehicle;
    const quoteDeliveryScheduleTemplates = response.data.quoteDeliveryScheduleTemplate;
    const quoteDeliveryScheduleDispatcher = response.data.quoteDeliveryScheduleDispatcher;
    const quoteTypes = response.data.quoteType;
    const storeTypes = response.data.storeType;

    await indexedDb.addData(indexedDb.table.client, clients);
    await indexedDb.addData(indexedDb.table.country, countries);
    await indexedDb.addData(indexedDb.table.location, locations);
    await indexedDb.addData(indexedDb.table.warehouse, warehouses);
    await indexedDb.addData(indexedDb.table.worksOrderDepartment, worksOrderDepartments);
    await indexedDb.addData(indexedDb.table.projectManager, quoteProjectManagers);
    await indexedDb.addData(indexedDb.table.vehicle, vehicles);
    await indexedDb.addData(indexedDb.table.quoteDeliveryScheduleTemplate, quoteDeliveryScheduleTemplates);
    await indexedDb.addData(indexedDb.table.quoteDeliveryScheduleDispatcher, quoteDeliveryScheduleDispatcher);
    await indexedDb.addData(indexedDb.table.quoteType, quoteTypes);
    await indexedDb.addData(indexedDb.table.storeType, storeTypes);
  }

  return (
    <LoaderContainer config={pusherConfig} channel={"RHPLUS_QUOTES_LOADING_CHANNEL"}>
    <Provider store={store}>
      <ConnectedRouter history={history}>        
        <MainAppContext.Provider value={{ state, dispatch }}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </MainAppContext.Provider>
      </ConnectedRouter>
    </Provider>
    </LoaderContainer>
  );
}
