import { 
  PAGE_TITLE, 
  PAGE_SUBTITLE,
  PAGE_ADD_GROUP_BUTTONS,
  PAGE_ACTIVE_GROUP_BUTTON, 
  PAGE_CLEAR_PAGE 
} from "constants/ActionTypes";


const page = (state = {}, action) => {
  switch (action.type) {
    case PAGE_TITLE: 
      return {
        ...state,
        title: action.title
    }

    case PAGE_SUBTITLE: 
    return {
      ...state,
      subtitle: action.subtitle
  }

    case PAGE_ADD_GROUP_BUTTONS:
      return {
        ...state,
        buttons: action.buttons
      }

    case PAGE_ACTIVE_GROUP_BUTTON:
      return {
        ...state,
        activeButton: action.activeButton
      }

    case PAGE_CLEAR_PAGE:
      return {
        ...state,
        title: '',
        buttons: [],
        activeButton: '',
        subtitle: ''
      }

    default:
      return state;
  }
}

export default page;