import * as React from 'react';
import { AuthenticationContext, withAdalLogin } from 'react-adal';
import { Route } from 'react-router-dom';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
            <Component {...props} />
        )}}
    />);
}

export default RestrictedRoute;