import React from 'react';
import { connect } from 'react-redux';

class HeaderTitle extends React.Component {
  
  render() {
    const { title = ''} = this.props;

    return (
      <div>
      { 
        title.length > 0 ? (
          <div className="header-menu d-inline-block mt-3 ml-2 mr-3">
          <h2 className="title">{title}</h2>
        </div>  
        ) : (
          <div />
        )
      }
      </div>
    );
  }
}

const mapStateToProps = ({page}) => {
  const { title } = page;
  return { title };
}

export default connect(mapStateToProps)(HeaderTitle);