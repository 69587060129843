import {
  FETCH_WORKS_ORDERS,
  WORKS_ORDER_ADD_DELIVERY,
  WORKS_ORDER_DELETE
} from 'constants/ActionTypes';

const initial = {}

export default (state = initial, action) => {
  switch (action.type) {
    
    case FETCH_WORKS_ORDERS:
      const { worksOrders } = action;
      return {
        ...state,
        worksOrders 
      }
      break;

    case WORKS_ORDER_DELETE:
      return {
        ...state,
        worksOrders: state.worksOrders.map(item => {
          if (item.id == action.data) {
            return {
              assemblyCount: item.assemblyCount,
              department: item.department,
              departmentid: item.departmentId,
              departments: item.departments,
              id: item.id * -1,
              quoteId: item.quoteId
            }
          } else {
            return item;
          }
        })
      }

    case WORKS_ORDER_ADD_DELIVERY:
      const {worksOrderId, deliveryId, deliveryName} = action.data;
      return {
        ...state,
        worksOrders: state.worksOrders.map(item => {
          if (item.id === worksOrderId) {
            return {...item, 
              deliveryId,
              deliveryName
            }
          }
            else
              return item;
          })
        };
      break;

    default:
      return state;
  }
}

