
export const configuration = {
  dbName: 'RHplus',
  version: 1,
  storeConfig: [
    {
      name: 'country',
      keyPath: 'id',
      autoIncrement: false,
      indexes: [
        {
          fields: ['nazev', 'zkratka'],
          isUnique: true
        }
      ]
    },
    {
      name: 'location',
      keyPath: 'id',
      autoIncrement: false,
      indexes: [
        {
          fields: ['code', 'name', 'country'],
          isUnique: true
        }
      ]
    },
    {
      name: 'warehouse',
      keyPath: 'id',
      indexes: [
        {
          fields: ['code', 'name', 'primary', 'locationId'],
          isUnique: true
        }
      ]
    },
    {
      name: 'worksOrderDepartment',
      keyPath: 'id',
      indexes: [
        {
          fields: ['name'],
          isUnique: true
        }
      ]
    },
    {
      name: 'projectManager',
      keyPath: 'id',
      indexes: [
        {
          fields: ['name', 'phoneNumber', 'email'],
          isUnique: true
        }
      ]
    },
    {
      name: 'vehicle',
      keyPath: 'id',
      indexes: [
        {
          fields: ['name'],
          isUnique: true
        }
      ]
    },
    {
      name: 'quoteDeliveryScheduleTemplate',
      keyPath: 'id',
      indexes: [
        {
          fields: ['deliveryId', 'phasingDepartmentId'],
          isUnique: true
        }
      ]
    },
    {
      name: 'quoteDeliveryScheduleDispatcher',
      keyPath: 'id',
      indexes: [
        {
          fields: ['name'],
          isUnique: true
        }
      ]
    },
    {
      name: 'client',
      keyPath: 'id',
      autoIncrement: false,
      indexes: [
        {
          fields: ['name'],
          isUnique: true
        }
      ]
    },
    {
      name: 'quoteType',
      keyPath: 'id',
      autoIncrement: false,
      indexes: [
        {
          fields: ['name'],
          isUnique: true
        }
      ]
    },
    {
      name: 'storeType',
      keyPath: 'id',
      autoIncrement: false,
      indexes: [
        {
          fields: ['name'],
          isUnique: true
        }
      ]
    }  
  ]
};

