import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: '58ec973f-9c94-4e25-8019-d22e334fc973',
  clientId: '33a3aeb5-70bf-4dd1-8d70-5192e04e309a',
  endpoints: {
    api: '33a3aeb5-70bf-4dd1-8d70-5192e04e309a',
  },
  cacheLocation: 'localStorage',
  postLogoutRedirectUri: 'http://localhost:3000/logout'
};

export const authContext = new AuthenticationContext(adalConfig);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
export const authUser = () => authContext?._user?.profile || {};

