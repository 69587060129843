import * as React from "react";
import { PdfIcon } from "@bit/hlouzek.rhplus.icons/dist/Icons/RhPlus";
import { connect } from "react-redux";
import Enumerable from "linq";
import useFileAttachement from "components/FileAttachement";
import CircularProgress from "@material-ui/core/CircularProgress";

const HeaderDrawing = ({ fileAttachements }) => {
  const { downloadAzureFile } = useFileAttachement();
  const [loading, setLoading] = React.useState(false);

  const onFileClick = (e, azureFileName, fileName) => {
    e.preventDefault();
    setLoading(true);
    downloadAzureFile(azureFileName, fileName).then(() => {
      setLoading(false);
    })
  };

  const ShowIcon = () => {
    var drawing = Enumerable.from(fileAttachements).singleOrDefault(
      (w) => w.fileType === 6
    );

    if (drawing === null) return null;

    if (!!loading) {
      return (
        <div>
          <CircularProgress color="white" style={{width: 20, height: 20}} />
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <a href="" onClick={(e) => onFileClick(e, drawing.azureFileName, drawing.fileName)}>
            <PdfIcon className="rhplus-2x" />
          </a>
          <div>drawing</div>
        </div>
      );
    }
  };

  return (
    <div>
      <ShowIcon />
    </div>
  );
};

const mapStateToProps = ({ quotes }) => {
  const { fileAttachements } = quotes;
  return {
    fileAttachements,
  };
};

export default connect(mapStateToProps)(HeaderDrawing);

// <div>
// { subtitle && subtitle.length > 0 ? (
//     <div className="header-menu d-inline-block ml-2 mr-3">
//       <div className="subtitle">{subtitle}test</div>
//     </div>
//   ) : (
//     <div />
//   )
// }
// </div>

// <div className="user-detail">
// <h4 className="user-name" onClick={handleClick}>{firstName} {lastName}
//   <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
// </h4>
// </div>
