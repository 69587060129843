import {
  FETCH_DELIVERY_SCHEDULE_HEADER,
  FETCH_DELIVERY_SCHEDULE_ADD_DELIVERY,
  FETCH_DELIVERY_SCHEDULE_REMOVE_DELIVERY
} from 'constants/ActionTypes';

const initial = {}

export default (state = initial, action) => {
  let Enumerable = require('linq');

  switch (action.type) {
    
    case FETCH_DELIVERY_SCHEDULE_HEADER:
      const { header } = action;
      return {
        ...state,
        header 
      }
      break;

    case FETCH_DELIVERY_SCHEDULE_ADD_DELIVERY:
      const {worksOrder} = action;
      return {
        ...state,
        deliveries: state.deliveries.map(delivery => {
          if (delivery.deliveryId === worksOrder.deliveryId) {
            return {
              ...delivery,
              worksOrders: [...delivery.worksOrders, worksOrder]
            }
          } else {
            return delivery;
          }
        })
      }

    case FETCH_DELIVERY_SCHEDULE_REMOVE_DELIVERY:
      const {id} = action;
      return {
        ...state,
        deliveries: Enumerable
                      .from(this.state.deliveries)
                      .where(w => w.id != id)
                      .toArray()
      }

    default:
      return state;
  }
}