import {quotes} from 'api/apiService';
import useApi, {errorNotification} from '@bit/hlouzek.rhplus.api-component';
import { reject } from 'lodash';

export default function useFileAttachement() {
  const {getQuoteFileAttachement} = quotes;
  const {callApi, errorCatch} = useApi(process.env.REACT_APP_PROXY);

  const downloadFileStream = (stream, fileName, contentType) => {
    return new Promise((resolve) => {
      try
      {
        const file = new Blob([stream], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        var tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.download = fileName;
        tempLink.click();
        resolve();                
      }
      catch (error) { reject(error) }});
  }


  const downloadAzureFile = (azureFileName, fileName) => {
    return new Promise((resolve, reject) => {
      callApi({...getQuoteFileAttachement(azureFileName)})
      .then(response => {
        const file = new Blob([response.data], { type: response.headers["content-type"] });
        const fileURL = URL.createObjectURL(file);
        var tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.download = fileName;
        tempLink.click();
        resolve();
      })
      .catch(error => {
        reject();
        console.log("ERROR", error);
      });  
    });
  }

  return {
    downloadAzureFile,
    downloadFileStream
  }
}