import IndexedDb from "IndexedDb";
import {
  FETCH_ALL_EXCEL_DATA,
  FETCH_QUOTE_HEAD,
  FETCH_QUOTE_TKM,
  FETCH_QUOTE_HS,
  FETCH_QUOTE_ASSEMBLIES,
  FETCH_QUOTE_PHASINGS,
  CHANGE_QUOTE_ASSEMBLY_QUANTITY,
  CHANGE_QUOTE_ASSEMBLY,
  UNDO_CHANGE_QUOTE_ASSEMBLY,
  FETCH_QUOTE_FIXTURE_SCHEDULE,
  FETCH_QUOTE_GIFT_AND_HOME,
  FETCH_QUOTE_PEAK_AND_FLEX,
  CLEAR_QUOTES,
  FETCH_CREATE_FINAL_ACCOUNT,
  FETCH_COVERING_LETTERS,
  FETCH_FILE_ATTACHEMENTS,
  FETCH_FILE_DRAWING,
  SAVED_QUOTE_ASSEMBLY,
  UPDATE_QUOTE_ASSEMBLY_NOTE,
  UPDATE_QUOTE_ASSEMBLIES_STOCK,
  UPDATE_QUOTE_ASSEMBLY_LOCATION,
  UPDATE_QUOTE_ASSEMBLY_WAREHOUSE,
  FETCH_QUOTE_REVIT_SHEETS,
  CHANGE_ACTIVITY_ASSEMBLIES,
  ACCEPT_PROPOSED_PRODUCT,
  UPDATE_QUOTE_PHASING_DEPARTMENT,
  UPDATE_QUOTE_PHASING_DEPARTMENTS,
  FETCH_DELIVERY_SCHEDULE,
  UPDATE_WORKS_ORDER_DELIVERY,
  UPDATE_DELIVERY_SCHEDULE_DISPATCHERS,
  UPDATE_DELIVERY_SCHEDULE,
  RESOLVE_ASSEMBLY_ISSUE,
  FETCH_SAGE_LOADING
} from 'constants/ActionTypes';
import { getColumnDefs } from "components/ag-grid";
import Enumerable from 'linq';
import '@bit/hlouzek.rhplus.linq';

const initialQuotes = {}

export default (state = initialQuotes, action) => {
  const { data } = action;
  let _cols, rowColumn;

  switch (action.type) {
    case FETCH_ALL_EXCEL_DATA:
      return {
        ...state,
        data
      }

    case FETCH_QUOTE_HEAD:
      return {
        ...state,
        quote: action.data
      }


    case FETCH_QUOTE_TKM:

      if (data.columns === null)
        return state;

      _cols = getColumnDefs(data.columns);
      rowColumn = {
        headerName: "Row2",
        field: "row2",
        valueGetter: "node.id",
        resizable: true,
        widthInPixels: 80,
        width: 80
      };
      _cols = [rowColumn, ..._cols];

      _cols.forEach(item => {
        if (item.field === "0") {
          item.showRowGroup = true;
          item.cellRenderer = "agGroupCellRenderer";
          item.cellRendererParams = {
            innerRenderer: "simpleCellRenderer"
          }
        }
      });

      data.columns = _cols;
      return {
        ...state,
        quote_tkm: data
      }

    case FETCH_QUOTE_HS:
      if (data.columns === null)
        return state;

      _cols = getColumnDefs(data.columns);
      rowColumn = {
        headerName: "Row",
        field: "row",
        valueGetter: "node.id",
        resizable: true,
        widthInPixels: 80,
        width: 80
      };
      _cols = [rowColumn, ..._cols];

      _cols = _cols.map(item => {
        if (item.field === "1") {
          item.showRowGroup = true;
          item.cellRenderer = "agGroupCellRenderer";
          item.cellRendererParams = {
            innerRenderer: "simpleCellRenderer"
          }
        }
        return item;
      });
      data.columns = _cols;

      return {
        ...state,
        quote_hs: data
      }

    case FETCH_QUOTE_ASSEMBLIES:
      const _assemblies = 
        data.map(assembly => ({
          ...assembly,
          assemblyExist: () => !!assembly.assemblyId
        }));

      const fetchQuoteAssemblies = {
        ...state,
        assemblies: _assemblies
      }
      return fetchQuoteAssemblies;

    case FETCH_SAGE_LOADING:
      return {
        ...state,
        sageLoading: data
      }

    case UPDATE_QUOTE_ASSEMBLY_NOTE:
      return {
        ...state,
        assemblies: state.assemblies.map(assembly => {
          if (assembly.id === action.quoteAssemblyId) {
            return {
              ...assembly,
              note: action.note
            }
          }
          return assembly;
        })
      }

    case UPDATE_QUOTE_ASSEMBLY_WAREHOUSE:
      var updateQuoteAssemblyWarehouseResult = {
        ...state,
        assemblies: state.assemblies.map(assembly => {
          if (assembly.row === action.row) {
            return {
              ...assembly,
              ...action.data,
              products: assembly.products.map(product => ({
                ...product,
                ...action.data
              }))
            }
          }
          return assembly;
        })
      }
      return updateQuoteAssemblyWarehouseResult;

    case UPDATE_QUOTE_ASSEMBLY_LOCATION:
      const {
        warehouseId: _1_warehouseId, 
        warehouse: _1_warehouse,
        locationId: _1_locationId, 
        location: _1_location
      } = action.data.item;
      
      var uodateQuoteAssemblyStockResult = {
        ...state,
        assemblies: state.assemblies.map(assembly => {
          if (assembly.row === action.row) {
            var r1 = {
              ...assembly,
              warehouseId: _1_warehouseId,
              warehouse: _1_warehouse,
              locationId: _1_locationId,
              location: _1_location,
              origin: {
                ...assembly.origin,
                warehouseId: _1_warehouseId,
                warehouse: _1_warehouse,
                locationId: _1_locationId,
                location: _1_location
              }
            }
            return r1;
          }
          return assembly;
        })
      }
      return uodateQuoteAssemblyStockResult;


    case UPDATE_QUOTE_ASSEMBLIES_STOCK:
      var updatedAssemblies = Enumerable.from(state.assemblies)
        .join(Enumerable.from(data), 
          fk => fk.id, pk => pk.id, 
          (left, right) => ({left, right}))
        .select(s => ({
          ...s.left,
          warehouseId: s.right.warehouseId,
          warehouse: s.right.warehouse,
          locationId: s.right.locationId,
          location: s.right.location,
          origin: {
            ...s.left.origin,
            warehouseId: s.right.warehouseId,
            warehouse: s.right.warehouse,
            locationId: s.right.locationId,
            location: s.right.location
          }
        }))
        .toArray();

        var updateQuoteAssembliesStockUpdate = {
          ...state,
          quote: {
            ...state.quote,
            locationId: action.locationId,
          },
          assemblies: updatedAssemblies
        }
        return updateQuoteAssembliesStockUpdate;

    case FETCH_CREATE_FINAL_ACCOUNT:
      return {
        ...state,
        createFinalAccount: data
      }

    case FETCH_QUOTE_PHASINGS:
      const ree = {
        ...state,
        phasings: data.map(item => {          
          return {
            ...item,            
            quoteAssembly: {
              ...item.quoteAssembly,
              basementQuantity: item.quoteAssemblyTypeId === 1 ? item.quoteAssembly.basementQuantity: 0,
              groundFloorQuantity: item.quoteAssemblyTypeId === 1 ? item.quoteAssembly.groundFloorQuantity: 0,
              firstFloorQuantity: item.quoteAssemblyTypeId === 1 ? item.quoteAssembly.firstFloorQuantity: 0,
              secondFloorQuantity: item.quoteAssemblyTypeId === 1 ? item.quoteAssembly.secondFloorQuantity: 0,
              thirdFloorQuantity: item.quoteAssemblyTypeId === 1 ? item.quoteAssembly.thirdFloorQuantity: 0,  
              quantity: item.quoteAssemblyTypeId === 1 ? item.quoteAssembly.quantity : 0,
              peakAndFlex: item.quoteAssemblyTypeId === 2 ? item.quoteAssembly.peakAndFlex : 0,
              additKit: item.quoteAssemblyTypeId === 3 ? item.quoteAssembly.additKit : 0,
              signHolder: item.quantityAssemblyTypeId === 4 ? item.quoteAssembly.signHolder : 0  
            },
            quantity: item.quoteAssemblyTypeId === 1 ? item.quoteAssembly.quantity : 0,
            peakAndFlex: item.quoteAssemblyTypeId === 2 ? item.quoteAssembly.peakAndFlex : 0,
            additKit: item.quoteAssemblyTypeId === 3 ? item.quoteAssembly.additKit : 0,
            signHolder: item.quantityAssemblyTypeId === 4 ? item.quoteAssembly.signHolder : 0
          }
        })
      }
      console.log("phasing", ree.phasings);
      console.log("phasing", ree.phasings.filter(f => f.quoteAssembly.assemblyCode === "JJ000927"));
      return ree;

    case FETCH_QUOTE_REVIT_SHEETS:
      if (data == null)
        return state;

      var sheets = data.map(sheet => {
        _cols = getColumnDefs(sheet.columns);
        sheet.columns = _cols;
        return sheet;
      })
      return {
        ...state,
        revitSheets: sheets
      }

    case FETCH_QUOTE_FIXTURE_SCHEDULE:
      if (data == null || data.columns === null)
        return state;

      _cols = getColumnDefs(data.columns);
      rowColumn = {
        headerName: "Row",
        field: "row",
        valueGetter: "node.id",
        resizable: true,
        widthInPixels: 80,
        width: 80
      };
      _cols = [rowColumn, ..._cols];
      data.columns = _cols;
      return {
        ...state,
        fixtureSchedule: data
      }

    case FETCH_QUOTE_GIFT_AND_HOME:
      if (data == null || data.columns === null)
        return state;

      _cols = getColumnDefs(data.columns);
      rowColumn = {
        headerName: "Row",
        field: "row",
        valueGetter: "node.id",
        resizable: true,
        widthInPixels: 80,
        width: 80
      };
      _cols = [rowColumn, ..._cols];
      data.columns = _cols;
      return {
        ...state,
        giftAndHome: data
      }


    case FETCH_QUOTE_PEAK_AND_FLEX:
      
      if (data == null || data.columns === null)
        return state;
      
      _cols = getColumnDefs(data.columns);
      rowColumn = {
        headerName: "Row",
        field: "row",
        valueGetter: "node.id",
        resizable: true,
        widthInPixels: 80,
        width: 80
      };
      _cols = [rowColumn, ..._cols];
      data.columns = _cols;
      return {
        ...state,
        quote_PF: data
      }

    case FETCH_COVERING_LETTERS:
      return {
        ...state,
        coveringLetters: action.data
      }

    case FETCH_FILE_ATTACHEMENTS:
      return {
        ...state,
        fileAttachements: action.data
      }

    case FETCH_FILE_DRAWING:
      return {
        ...state,
        drawing: action.data
      }

    case CHANGE_ACTIVITY_ASSEMBLIES:
      const {ids, ignore} = action.data;
      var tt2 = {
        ...state,
        assemblies: state.assemblies.map(item => {
          if (ids.includes(item.id)) {
            return {
              ...item,
              products: item.products.map(product => ({
                ...product,
                ignore
              })),
              ignore
            }
          } 
          return item;
        }),
        phasings: state.phasings.map(phasing => {
          const {quoteAssembly} = phasing;
          if (ids.includes(quoteAssembly.id)) {
            return {
              ...phasing,
              quoteAssembly: {
                ...phasing.quoteAssembly,
                ignore
              } 
            }
          }
          return phasing;
        })
      }
      return tt2;

    // case CHANGE_ACTIVITY_ASSEMBLY:
    //   const {id, ignore} = action.data;
    //   var tt = {
    //     ...state,
    //     assemblies: state.assemblies.map(item => {
    //       if (item.id === id) {
    //         return {
    //           ...item,
    //           products: item.products.map(product => ({
    //             ...product,
    //             ignore
    //           })),
    //           ignore
    //         }
    //       } 
    //       return item;
    //     }),
    //     phasings: state.phasings.map(phasing => {
    //       const {quoteAssembly} = phasing;
    //       if (quoteAssembly.id === id) {
    //         return {
    //           ...phasing,
    //           quoteAssembly: {
    //             ...phasing.quoteAssembly,
    //             ignore
    //           } 
    //         }
    //       }
    //       return phasing;
    //     })
    //   }
    //   return tt;

    case CHANGE_QUOTE_ASSEMBLY_QUANTITY:
      return {
        ...state,
        assemblies: state.assemblies.map(item => {
          if (item.row === action.row) {
            item.changed = true;
            item.quantity = Number(action.value);
            item.total = Number(action.value) + Number(item.additKit);
          }
          return item;
        })
      }

    case SAVED_QUOTE_ASSEMBLY:
      return state;
      break;
      // const updated = Enumerable.from(state.assemblies)
      //   .merge(action.data,
      //     pk => pk.id,
      //     fk => fk.id,
      //     (left, right) => right || left)
      //   .toArray();
      // return {
      //   ...state,
      //   assemblies: updated.map(item => ({
      //     ...item,
      //     changed: false
      //   }))
      // }

    case CHANGE_QUOTE_ASSEMBLY:

      const changedQuoteAssembly = {
        ...state,
        assemblies: state.assemblies.map(item => {
          if (item.row === action.row) {

            item.changed = true;
            item.assemblyExist = true;
            item.id = action.assembly.id;
            item.assemblyId = action.assembly.assemblyId;
            item.assemblyCode = action.assembly.assemblyCode;
            item.assemblyTjxCode = action.assembly.assemblyTjxCode;
            item.assemblyDescription = action.assembly.assemblyDescription;
            item.displayAssemblyCodePrefix = action.assembly.displayAssemblyCodePrefix;
            item.displayAssemblyCodeMiddle = action.assembly.displayAssemblyCodeMiddle;
            item.warehouse = action.warehouse;
            item.warehouseId = action.warehouse.id;
            item.locationId = action.warehouse.locationId;
            item.products = action.assembly.products.map(product => {
              return {
                assemblyId: action.assembly.assemblyId,
                id: product.id,
                productId: product.productId,
                productCode: product.productCode,
                productDescription: product.productDescription,
                unitQuantity: product.unitQuantity,
                totalQuantity: product.totalQuantity,
                quantity: product.quantity,
                unit: product.unit,
                supplier: product.supplier,
                productTjxCode: product.productTjxCode
              }
            })
          };

          return item;
        })
      }

      return changedQuoteAssembly;

    case ACCEPT_PROPOSED_PRODUCT:
      return {
        ...state,
        assemblies: state.assemblies.map(item => {
          if (item.id === action.data.id) {
            return {
              ...item,
              accepted: action.data.accept
            }
          }
          return item;
        })
      }

    case UNDO_CHANGE_QUOTE_ASSEMBLY:
      return {
        ...state,
        assemblies: state.assemblies.map(item => {
          if (item.row === action.row) {
            return {
              ...item,
              ...item.origin
            }
          }
          return item;
        })
      }

    case RESOLVE_ASSEMBLY_ISSUE:
      var tt = {
        ...state,
        assemblies: state.assemblies.map(item => {
          if (item.id === action.data.id) {
            return {
              ...item,
              resolvedIssue: true
            }
          }
          return item;
        })
      }
      console.log("🚀 ~ file: Quotes.js:668 ~ tt:", tt);

      return tt;

    case CLEAR_QUOTES:
      return {
        ...state,
        assemblies: [],
        peakAndFlex: [],
        giftAndHome: [],
        fixtureSchedule: [],
        phasings: [],
        quote_hs: [],
        quote_tkm: [],
        quote: []
      }

    case UPDATE_QUOTE_PHASING_DEPARTMENT:
      const {id: phasingId, data: {item}} = action.data;
      return {
        ...state,
        phasings: state.phasings.map(phasing => {
          if (phasing.id === phasingId) {
            return {
              ...phasing,
              ...item
            }
          } else {
              return phasing;
            }
          }
        )
      }

      case UPDATE_QUOTE_PHASING_DEPARTMENTS:

        var phasings = Enumerable.from(state.phasings)
          .groupJoin(Enumerable.from(action.data.data.items),
              pk => pk.id,
              fk => fk.id,
              (left, right) => ({...left, right}))
          .selectMany(m => m.right.defaultIfEmpty(),
            (left, right) => {
              const {sign} = right || {};
              return right === null ? {...left} : {...right}; 
            })
          .toArray();

        return {
          ...state,
          phasings
          //deliverySchedule: deliveryScheduleSelectorAsync()
        }


        // const {id: phasingId, data: {item}} = action.data;
        // return {
        //   ...state,
        //   phasings: state.phasings.map(phasing => {
        //     if (phasing.id === phasingId) {
        //       return {
        //         ...phasing,
        //         ...item
        //       }
        //     } else {
        //         return phasing;
        //       }
        //     }
        //   )
        // }

        case FETCH_DELIVERY_SCHEDULE:
          return {
            ...state,
            deliverySchedule: action.data
          }

        case UPDATE_WORKS_ORDER_DELIVERY:

          const removedItem = {
            ...state,
            deliverySchedule: {
              ...state.deliverySchedule,
              deliveryScheduleDelivery: state.deliverySchedule.deliveryScheduleDelivery.map(dsd => ({
                ...dsd,
                deliveryScheduleDeliveryWorksOrders: dsd.deliveryScheduleDeliveryWorksOrders
                  .filter(wo => wo.worksOrderId !== action.data.item.worksOrderId)
              }))
            } 
          }

          const addedItem = {
            ...removedItem,
            deliverySchedule: {
              ...removedItem.deliverySchedule,
              deliveryScheduleDelivery: removedItem.deliverySchedule.deliveryScheduleDelivery.map(dsd => {
                if (dsd.id === action.data.item.deliveryScheduleDeliveryId) {
                  return {
                    ...dsd,
                    deliveryScheduleDeliveryWorksOrders: [
                      ...dsd.deliveryScheduleDeliveryWorksOrders,
                      action.data.item
                    ]
                  }
                } else {
                  return dsd;
                }
              })
            }
          }
          return addedItem;

        case UPDATE_DELIVERY_SCHEDULE_DISPATCHERS:
          return {
            ...state,
            deliverySchedule: {
              ...state.deliverySchedule,
              deliveryScheduleDelivery: state.deliverySchedule.deliveryScheduleDelivery.map(delivery => {
                if (delivery.id === action.data.deliveryId) {
                  return {
                    ...delivery,
                    deliveryScheduleDeliveryDispatchers: action.data.items
                  }
                } else {
                  return delivery;
                }
              })
            }
          }
        
        case UPDATE_DELIVERY_SCHEDULE:
          return {
            ...state,
            deliverySchedule: {
              ...state.deliverySchedule,
              ...action.data
            }
          }
          break;
          
          return state;
        default:
      return state;
  }
};

const deliveryScheduleSelectorAsync = async phasings => {
  const indexedDb = new IndexedDb();
  const templates = await indexedDb.quoteDeliveryScheduleTemplates();


  return {}
}