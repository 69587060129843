import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Quotes from './Quotes';
import Page from './Page';
import Projects from './Projects';
import DeliverySchedule from './DeliverySchedule';
import WorksOrder from './WorksOrder';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,    
  quotes: Quotes,
  page: Page,
  deliverySchedule: DeliverySchedule,
  worksOrder: WorksOrder,
  projects: Projects
});
