
const cors = {
  "Content-Type": "text/json;charset=utf-8",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
}

const createQuote = {
  url: `/api/Quotes/CreateQuote`,
  methodType: "PUT",
  version: "1.0",
  // headers: cors,
  compression: true
  // showSuccessNotification: true,
  // showErrorNotification: true,
  // successNotificationMessage: "Quote has been successfully created",  
};

const detailQuote = {
  url: `/api/quotes/detailquote`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const detailExcelQuote = {
  url: `/apiquoteerp/Quotes/DetailExcelQuote`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const detailExcelRevit = {
  url: `/apiquoteerp/Quotes/DetailExcelRevit`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const quoteList = () => {
  return {
    url: `/api/Quotes/QuoteList`,
    methodType: "GET",
    version: "1.0",
  };
};

const allCatalogs = () => {
  return {
    url: `/api/Quotes/AllCatalogs`,
    methodType: "GET",
    version: "1.0",
  };
};

const createFinalAccount = {
  url: `/api/Quotes/CreateFinalAccount`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const createWorksOrders = {
  url: `/api/Quotes/CreateWorksOrders`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const checkDeleteWorksorder = {
  url: `/api/Quotes/CheckDeleteWorksorder`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const deleteWorksorder = {
  url: `/api/Quotes/deleteworksorder`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const worksOrders = (quoteId) => {
  return {
    url: `/api/Quotes/WorksOrders/${quoteId}`,
    methodType: "GET",
    version: "1.0",
  };
};

const deliverySchedule = (quoteId) => {
  return {
    url: `/api/Quotes/DeliverySchedule/${quoteId}`,
    methodType: "GET",
    version: "1.0",
  };
};

const worksOrderMoveToDelivery = {
  url: `/api/Quotes/WorksOrderMoveToDelivery`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const countryProducts = {
  url: `/api/Quotes/countryproducts`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const deactiveQuote = {
  url: `/api/Quotes/DeactiveQuote`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const removeQuote = {
  url: `/api/Quotes/RemoveQuote`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const deleteQuote = {
  url: `/api/Quotes/DeleteQuote`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const updateQuote = {
  url: `/api/Quotes/UpdateQuote`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const projectsQuote = {
  url: `/api/Quotes/ProjectsQuote`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const createProject = {
  url: `/api/Quotes/CreateProject`,
  methodType: "POST",
  version: "1.0",
  compression: true,
  showSuccessNotification: true,
  showErrorNotification: true,
  successNotificationMessage: "Project has been created.",
};

const saveQuoteHeader = {
  url: `/api/Quotes/SaveQuoteHeader`,
  methodType: "PUT",
  version: "1.0",
  compression: true,
  showSuccessNotification: true,
  showErrorNotification: true,
  successNotificationMessage: "Quote detail has been successfully updated.",
};

const fulltextProducts = {
  url: `/api/product/fulltextproducts`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const searchProductsByLocationAndProducts = {
  url: `/api/product/searchproductsbylocationandproducts`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const getAssemblies = {
  url: `/api/Product/GetAssemblies`,
  methodType: "POST",
  version: "1.0",
  compression: true,
};

const saveQuoteAssembly = {
  url: `/api/quotes/updatequoteassemblies`,
  methodType: "POST",
  version: "1.0",
};

const undoQuoteAssembly = {
  url: `/api/quotes/undoquoteassembly`,
  methodType: "POST",
  version: "1.0",
};

const saveQuoteAssemblyLocation = {
  url: `/api/quotes/savequoteassemblylocation`,
  methodType: "POST",
  version: "1.0",
};

const saveQuoteAssemblyNote = {
  url: `/api/quotes/savequoteassemblynote`,
  methodType: "POST",
  version: "1.0",
};

const saveQuoteProductWarehouse = {
  url: `/api/quotes/savequoteproductwarehouse`,
  methodType: "POST",
  version: "1.0",
};

const saveQuoteStock = {
  url: `/api/quotes/savequotestock`,
  methodType: "POST",
  version: "1.0",
};

const ignoreAssemblies = {
  url: `/api/quotes/ignoreassemblies`,
  methodType: "POST",
  version: "1.0",
};

const ignorePermanentlyAssemblies = {
  url: `/api/quotes/ignorepermanentlyassemblies`,
  methodType: "POST",
  version: "1.0",
};

const activateAssemblies = {
  url: `/api/quotes/activateassemblies`,
  methodType: "POST",
  version: "1.0",
};

const activatePermanentlyAssemblies = {
  url: `/api/quotes/activatepermanentlyassemblies`,
  methodType: "POST",
  version: "1.0",
};

const resolvedIssue = {
  url: `/api/quotes/resolvedissue`,
  methodType: "POST",
  version: "1.0",
};

const acceptProposedProduct = {
  url: `/api/rules/acceptproposedproduct`,
  methodType: "POST",
  version: "1.0",
};

const proposedProductRules = {
    url: `/api/rules/proposedproductrules`,
    methodType: "POST",
    version: "1.0",
};

const deleteProposedProductRule = {
  url: `/api/rules/deleteproposedproductrule`,
  methodType: "POST",
  version: "1.0",
};

const updateProposedProductScope = {
  url: `/api/rules/updateproposedproductscope`,
  methodType: "POST",
  version: "1.0",
};

const updateProposedProductRule = {
  url: `/api/rules/updateproposedproductrule`,
  methodType: "POST",
  version: "1.0",
};

const updateProposedExportQuoteProduct = {
  url: `/api/rules/UpdateProposedExportQuoteProduct`,
  methodType: "POST",
  version: "1.0",
};

const createProposedProductRule = {
  url: `/api/rules/createproposedproductrule`,
  methodType: "POST",
  version: "1.0",
};


const changeQuoteForecastStatus = {
  url: `/api/quotes/changequoteforecaststatus`,
  methodType: "POST",
  version: "1.0",
  showSuccessNotification: true,
  successNotificationMessage: "Successfully changed",
};

const getQuoteFileAttachement = (filename) => {
  return {
      url: `/api/quotes/getQuoteFileAttachement/${filename}`,
      methodType: "GET",
      responseType: "arraybuffer",
      headers: {
        'Content-Type': 'application/json',
        //'Accept': 'application/pdf'
      },
      version: "1.0"
  }
}

const exportQuoteToExcelSimple = (quoteId) => {
  return {
    url: `/api/quotes/exportQuoteToExcelSimple/${quoteId}`,
    methodType: "GET",
    responseType: "arraybuffer",
    headers: {
      'Content-Type': 'application/json',
      //'Accept': 'application/pdf'
    },
    version: "1.0"
  }
}

const exportQuoteToExcelComplex = (quoteId) => {
  return {
    url: `/api/quotes/exportQuoteToExcelComplex/${quoteId}`,
    methodType: "GET",
    responseType: "arraybuffer",
    headers: {
      'Content-Type': 'application/json',
      //'Accept': 'application/pdf'
    },
    version: "1.0"
  }
}

const saveQuotePhasingDepartment = {
  url: `/api/quotes/savequotephasingdepartment`,
  methodType: "POST",
  version: "1.0",
};

const saveQuotePhasingDepartments = {
  url: `/api/quotes/savequotephasingdepartments`,
  methodType: "POST",
  version: "1.0",
};

const changeWorksOrderDelivery = {
  url: `/api/quotes/changeworksorderdelivery`,
  methodType: "POST",
  version: "1.0",
};

const saveDeliverySchedule = {
  url: `/api/quotes/savedeliveryschedule`,
  methodType: "POST",
  version: "1.0",
};

const saveDeliveryScheduleDispatcher = {
  url: `/api/quotes/SaveDeliveryScheduleDispatcher`,
  methodType: "POST",
  version: "1.0",
};

const saveDeliveryScheduleDateOnSite = {
  url: `/api/quotes/saveDeliveryScheduleDateOnSite`,
  methodType: "POST",
  version: "1.0",
};

const saveDeliveryScheduleTimeOnSite = {
  url: `/api/quotes/saveDeliveryScheduleTimeOnSite`,
  methodType: "POST",
  version: "1.0",
};

export const quotes = {
  createQuote,
  detailQuote,
  detailExcelQuote,
  detailExcelRevit,
  quoteList,
  getAssemblies,
  createFinalAccount,
  createWorksOrders,
  worksOrders,
  deliverySchedule,
  worksOrderMoveToDelivery,
  deactiveQuote,
  removeQuote,
  updateQuote,
  projectsQuote,
  createProject,
  saveQuoteHeader,
  allCatalogs,
  fulltextProducts,
  saveQuoteAssembly,
  saveQuoteAssemblyLocation,
  saveQuoteStock,
  saveQuoteProductWarehouse,
  searchProductsByLocationAndProducts,
  undoQuoteAssembly,
  ignoreAssemblies,
  ignorePermanentlyAssemblies,
  activateAssemblies,
  activatePermanentlyAssemblies,
  acceptProposedProduct,
  deleteQuote,
  proposedProductRules,
  deleteProposedProductRule,
  updateProposedProductScope,
  updateProposedExportQuoteProduct,
  createProposedProductRule,
  getQuoteFileAttachement,
  changeQuoteForecastStatus,
  saveQuotePhasingDepartment,
  saveQuotePhasingDepartments,
  changeWorksOrderDelivery,
  saveDeliverySchedule,
  saveDeliveryScheduleDispatcher,
  saveDeliveryScheduleDateOnSite,
  saveDeliveryScheduleTimeOnSite,
  exportQuoteToExcelSimple,
  exportQuoteToExcelComplex,
  countryProducts,
  updateProposedProductRule,
  saveQuoteAssemblyNote,
  resolvedIssue,
  checkDeleteWorksorder,
  deleteWorksorder
};
