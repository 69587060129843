//// Quotes modules
export const FETCH_ALL_EXCEL_DATA = 'fetch_all_excel_data';
export const FETCH_QUOTE_HEAD = 'fetch_quote_head';
export const FETCH_QUOTE_TKM = 'fetch_quote_tkm';
export const FETCH_QUOTE_HS = 'fetch_quote_hs';
export const FETCH_QUOTE_ASSEMBLIES = 'fetch_quote_assemblies';
export const FETCH_QUOTE_PHASINGS = 'FETCH_QUOTE_PHASINGS';
export const CHANGE_QUOTE_ASSEMBLY_QUANTITY = 'change_quote_assembly_quantity';
export const CHANGE_QUOTE_ASSEMBLY = 'change_quote_assembly';
export const UNDO_CHANGE_QUOTE_ASSEMBLY = 'undo_change_quote_assembly';
export const FETCH_QUOTE_FIXTURE_SCHEDULE = 'fetch_quote_fixture_schedule';
export const FETCH_QUOTE_GIFT_AND_HOME = 'fetch_quote_gift_and_home';
export const FETCH_QUOTE_PEAK_AND_FLEX = 'fetch_quote_peak_and_flex';
export const FETCH_QUOTE_REVIT_SHEETS = 'FETCH_QUOTE_REVIT_SHEETS';
export const TEST = 'test';
export const CLEAR_QUOTES = 'clear_quotes';
export const FETCH_CREATE_FINAL_ACCOUNT = 'fetch_create_final_account';
export const FETCH_COVERING_LETTERS = 'fetch_covering_letters';
export const FETCH_FILE_ATTACHEMENTS = 'fetch_file_attachements';
export const FETCH_FILE_DRAWING = 'fetch_drawing';
export const SAVED_QUOTE_ASSEMBLY = 'saved_quote_assembly';
export const RESOLVE_ASSEMBLY_ISSUE = 'resolve_assembly_issue';
export const UPDATE_QUOTE_ASSEMBLIES_STOCK = 'UPDATE_QUOTE_ASSEMBLIES_STOCK';
export const UPDATE_QUOTE_ASSEMBLY_LOCATION = 'UPDATE_QUOTE_ASSEMBLY_LOCATION';
export const UPDATE_QUOTE_ASSEMBLY_NOTE = 'UPDATE_QUOTE_ASSEMBLY_NOTE';
export const UPDATE_QUOTE_ASSEMBLY_WAREHOUSE = 'UPDATE_QUOTE_ASSEMBLY_WAREHOUSE';
export const CHANGE_ACTIVITY_ASSEMBLY = 'CHANGE_ACTIVITY_ASSEMBLY';
export const CHANGE_ACTIVITY_ASSEMBLIES = 'CHANGE_ACTIVITY_ASSEMBLIES';
export const ACCEPT_PROPOSED_PRODUCT = 'ACCEPT_PROPOSED_PRODUCT';
export const FETCH_DELIVERY_SCHEDULE = 'FETCH_DELIVERY_SCHEDULE';
export const UPDATE_WORKS_ORDER_DELIVERY = 'UPDATE_WORKS_ORDER_DELIVERY';
export const UPDATE_DELIVERY_SCHEDULE_DISPATCHERS = 'UPDATE_DELIVERY_SCHEDULE_DISPATCHERS';
export const UPDATE_DELIVERY_SCHEDULE = 'UPDATE_DELIVERY_SCHEDULE';
export const FETCH_SAGE_LOADING = 'FETCH_SAGE_LOADING';

//phasing
export const UPDATE_QUOTE_PHASING_DEPARTMENT = 'UPDATE_QUOTE_PHASING_DEPARTMENT';
export const UPDATE_QUOTE_PHASING_DEPARTMENTS = 'UPDATE_QUOTE_PHASING_DEPARTMENTS';

// delivery schedule
export const FETCH_DELIVERY_SCHEDULE_HEADER = 'fetch_delivery_schedule_header';
export const FETCH_DELIVERY_SCHEDULE_DELIVERIES = 'fetch_delivery_schedule_deliveries';
export const FETCH_DELIVERY_SCHEDULE_ADD_DELIVERY = 'fetch_delivery_schedule_add_delivery';
export const FETCH_DELIVERY_SCHEDULE_REMOVE_DELIVERY = 'fetch_delivery_schedule_remove_delivery';

// works orders
export const FETCH_WORKS_ORDERS = 'fetch_works_orders';
export const WORKS_ORDER_ADD_DELIVERY = 'works_order_add_delivery';
export const WORKS_ORDER_DELETE = 'WORKS_ORDER_DELETE';

// Page const
export const PAGE_TITLE = 'page_title';
export const PAGE_SUBTITLE = 'page_subtitle';
export const PAGE_ADD_GROUP_BUTTONS = 'page_add_group_buttons';
export const PAGE_ACTIVE_GROUP_BUTTON = 'page_active_group_button';
export const PAGE_CLEAR_PAGE = 'page_clear_page';


// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

// quote projects
export const PROJECTS_FETCH_DATA = 'PROJECTS_FETCH_DATA';
export const PROJECTS_UPDATE_ITEM = 'PROJECTS_UPDATE_ITEM';
export const PROJECTS_REMOVE_ITEM = 'PROJECTS_REMOVE_ITEM';
export const PROJECTS_DEACTIVE_ITEM = 'PROJECTS_DEACTIVE_ITEM';

