import * as React from 'react';
import { LoaderContext } from './reducer/Context';
import uuidv4 from 'uuid/v4';
import { PusherProvider, useChannel, useEvent } from '@harelpls/use-pusher';

const _key = uuidv4();

const uncapitalizeKeys = (obj) => {
  const isObject = o => Object.prototype.toString.apply(o) === '[object Object]'
  const isArray = o => Object.prototype.toString.apply(o) === '[object Array]'

  let transformedObj = isArray(obj) ? [] : {}

  for (let key in obj) {
    // replace the following with any transform function
    const transformedKey = key.replace(/^\w/, (c, _) => c.toLowerCase())

    if (isObject(obj[key]) || isArray(obj[key])) {
      transformedObj[transformedKey] = uncapitalizeKeys(obj[key])
    } else {
      transformedObj[transformedKey] = obj[key]
    }
  }
  return transformedObj
}

const LoaderContainer = (props) => {
  const {config, children} = props;
  return (
    <PusherProvider {...config}>
      <InnerLoaderContainer {...props}>
        {children}      
      </InnerLoaderContainer>
    </PusherProvider>
  )
}

const InnerLoaderContainer = ({ children, channel }) => {

  const setMessage = message => {
    setValueContext(state => ({ ...state, message }));
  }

  const setPercent = percent => {
    setValueContext(state => ({ ...state, percent }));
  }

  const setLoading = loading => {
    if (loading)
      setValueContext(state => ({ ...state, loading }));
    else
      setValueContext(state => ({ ...state, loading, message: '', percent: undefined }));
  }

  const [valueContext, setValueContext] = React.useState({
    key: _key,
    message: '',
    percent: undefined,
    loading: false,
    setMessage,
    setLoading
  });

  const pusherChannel = useChannel(channel);

  useEvent(pusherChannel, _key.toString(), data => {
    const uncapitalizedMessage = uncapitalizeKeys(data);
    const {percent, message} = uncapitalizedMessage;
    setPercent(percent);
    setMessage(message);
  });

  return (
    <React.Fragment>
        <LoaderContext.Provider value={valueContext}>
          {children}
        </LoaderContext.Provider>
    </React.Fragment>
  )
}

export default LoaderContainer;