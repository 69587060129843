import * as React from "react";
import './Loader.css';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingIcon({percent, message, centered, classNames}) {
  
  const computeClassNames = (add) => {
    var centeredClassName = (!!centered) ?  "centered" : "";
    return classnames([centeredClassName, classNames, add]);
  }

  return (
    <div className={computeClassNames("text-center")}>
      <CircularProgress value={10} />
      <div style={{ marginTop: -37 }}>{percent && `${percent}%`} </div>
      <div className="mt-2">{message}</div>
    </div>
  );
}
